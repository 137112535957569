/**
 * ideal MRI
 * Signup Center API
 *
 * OpenAPI spec version: v1
 * Contact: ben@herila.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as querystring from "querystring";
import * as url from "url";

const isomorphicFetch = fetch;
const assign = Object.assign;

interface Dictionary<T> { [index: string]: T; }
export interface FetchAPI { (url: string, init?: any): Promise<any>; }

export const BASE_PATH = "https://mrisched.azurewebsites.net".replace(/\/+$/, "");

export interface FetchArgs {
    url: string;
    options: any;
}

export class BaseAPI {
    basePath: string;
    fetch: FetchAPI;

    constructor(fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) {
        this.basePath = basePath;
        this.fetch = fetch;
    }
};

export interface ApiResultAppointment {
    "value"?: Appointment;
    "success"?: boolean;
    "message"?: string;
    "stackTrace"?: Array<Stack>;
}

export interface ApiResultListAppointment {
    "value"?: Array<Appointment>;
    "success"?: boolean;
    "message"?: string;
    "stackTrace"?: Array<Stack>;
}

export interface ApiResultListAvailabilityRule {
    "value"?: Array<AvailabilityRule>;
    "success"?: boolean;
    "message"?: string;
    "stackTrace"?: Array<Stack>;
}

export interface ApiResultListServiceType {
    "value"?: Array<ServiceType>;
    "success"?: boolean;
    "message"?: string;
    "stackTrace"?: Array<Stack>;
}

export interface ApiResultListSlotAvailabilityDate {
    "value"?: Array<SlotAvailabilityDate>;
    "success"?: boolean;
    "message"?: string;
    "stackTrace"?: Array<Stack>;
}

export interface Appointment {
    "resourceId"?: string;
    "appointmentGuid"?: string;
    "serviceType"?: string;
    "serviceLength"?: number;
    "firstName"?: string;
    "lastName"?: string;
    "phone"?: string;
    "email"?: string;
    "address1"?: string;
    "address2"?: string;
    "city"?: string;
    "state"?: string;
    "zip"?: string;
    "height"?: string;
    "weight"?: string;
    "reminder"?: number;
    "doctorName"?: string;
    "doctorPhone"?: string;
    "insuranceCarrier"?: string;
    "insuranceGroupNumber"?: string;
    "insurancePolicyNumber"?: string;
    "insuranceVerified"?: boolean;
    "priorAuthObtained"?: boolean;
    "orderEnteredToRIS"?: boolean;
    "patientWasCalled"?: boolean;
    "confirmed"?: boolean;
    "safetyWarnings"?: string;
    "orderImageUrl"?: string;
    "insuranceFrontUrl"?: string;
    "insuranceBackUrl"?: string;
    "surveyDataJson"?: string;
    "approvedDate"?: Date;
    "submittedDate"?: Date;
    "confirmedDate"?: Date;
    "birthday"?: string;
    "partitionKey"?: string;
    "rowKey"?: string;
    "timestamp"?: Date;
    "eTag"?: string;
}

export interface AvailabilityRule {
    "priority"?: number;
    "resourceID"?: string;
    "onThisDay"?: string;
    "startTime"?: string;
    "endTime"?: string;
    "comment"?: string;
    "status"?: string;
    "partitionKey"?: string;
    "rowKey"?: string;
    "timestamp"?: Date;
    "eTag"?: string;
}

export interface CustomerLead {
    "firstName"?: string;
    "lastName"?: string;
    "dob"?: string;
    "phone"?: string;
    "email"?: string;
    "serviceType"?: string;
    "timeSlot"?: string;
    "withContrast"?: boolean;
    "partitionKey"?: string;
    "rowKey"?: string;
    "timestamp"?: Date;
    "eTag"?: string;
}

export interface Location {
    "partitionKey"?: string;
    "rowKey"?: string;
    "timestamp"?: Date;
    "eTag"?: string;
}

export interface ServiceType {
    "name"?: string;
    "contrast"?: string;
    "name2"?: string;
    "name3"?: string;
    "name4"?: string;
    "time"?: number;
}

export interface SlotAvailabilityDate {
    "date"?: Date;
    "friendlyBegin"?: string;
    "times"?: Array<SlotAvailabilityTime>;
    "isTomorrow"?: boolean;
}

export interface SlotAvailabilityTime {
    "time"?: string;
    "resourceId"?: string;
    "isHidden"?: boolean;
    "isAvailable"?: boolean;
    "isContrastAvailable"?: boolean;
    "isContrastRequired"?: boolean;
    "linkedAppointment"?: Appointment;
    "slotId"?: string;
}

export interface Stack {
    "in"?: string;
    "at"?: string;
}

export interface SurveyQuestion {
    "questionId"?: string;
    "questionShowIf"?: string;
    "questionShowIfAnswer"?: string;
    "questionText"?: string;
    "questionType"?: string;
    "isRequired"?: boolean;
    "isHidden"?: boolean;
    "order"?: number;
    "partitionKey"?: string;
    "rowKey"?: string;
    "timestamp"?: Date;
    "eTag"?: string;
}

export interface TableResult {
    "result"?: any;
    "httpStatusCode"?: number;
    "etag"?: string;
}

export interface User {
    "apiKey"?: string;
    "immutableId"?: string;
    "referenceRowKey"?: string;
    "partitionKey"?: string;
    "rowKey"?: string;
    "timestamp"?: Date;
    "eTag"?: string;
}



/**
 * AuthApi - fetch parameter creator
 */
export const AuthApiFetchParamCreator = {
    /**
     *
     * @summary Auth
     * @param req
     * @param authToken
     */
    auth(params: {  "req"?: User; "authToken"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/auth`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "authToken": params["authToken"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["req"]) {
            fetchOptions.body = JSON.stringify(params["req"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * AuthApi - functional programming interface
 */
export const AuthApiFp = {
    /**
     *
     * @summary Auth
     * @param req
     * @param authToken
     */
    auth(params: { "req"?: User; "authToken"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<User> {
        const fetchArgs = AuthApiFetchParamCreator.auth(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * AuthApi - object-oriented interface
 */
export class AuthApi extends BaseAPI {
    /**
     *
     * @summary Auth
     * @param req
     * @param authToken
     */
    auth(params: {  "req"?: User; "authToken"?: string; }, options?: any) {
        return AuthApiFp.auth(params, options)(this.fetch, this.basePath);
    }
};

/**
 * AuthApi - factory interface
 */
export const AuthApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary Auth
         * @param req
         * @param authToken
         */
        auth(params: {  "req"?: User; "authToken"?: string; }, options?: any) {
            return AuthApiFp.auth(params, options)(fetch, basePath);
        },
    };
};


/**
 * LeadGenApi - fetch parameter creator
 */
export const LeadGenApiFetchParamCreator = {
    /**
     *
     * @summary Run
     * @param req
     * @param authToken
     */
    runGET(params: {  "req"?: CustomerLead; "authToken"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/leadGen`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "authToken": params["authToken"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["req"]) {
            fetchOptions.body = JSON.stringify(params["req"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary Run
     * @param req
     * @param authToken
     */
    runPOST(params: {  "req"?: CustomerLead; "authToken"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/leadGen`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "authToken": params["authToken"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["req"]) {
            fetchOptions.body = JSON.stringify(params["req"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * LeadGenApi - functional programming interface
 */
export const LeadGenApiFp = {
    /**
     *
     * @summary Run
     * @param req
     * @param authToken
     */
    runGET(params: { "req"?: CustomerLead; "authToken"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerLead> {
        const fetchArgs = LeadGenApiFetchParamCreator.runGET(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary Run
     * @param req
     * @param authToken
     */
    runPOST(params: { "req"?: CustomerLead; "authToken"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerLead> {
        const fetchArgs = LeadGenApiFetchParamCreator.runPOST(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * LeadGenApi - object-oriented interface
 */
export class LeadGenApi extends BaseAPI {
    /**
     *
     * @summary Run
     * @param req
     * @param authToken
     */
    runGET(params: {  "req"?: CustomerLead; "authToken"?: string; }, options?: any) {
        return LeadGenApiFp.runGET(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Run
     * @param req
     * @param authToken
     */
    runPOST(params: {  "req"?: CustomerLead; "authToken"?: string; }, options?: any) {
        return LeadGenApiFp.runPOST(params, options)(this.fetch, this.basePath);
    }
};

/**
 * LeadGenApi - factory interface
 */
export const LeadGenApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary Run
         * @param req
         * @param authToken
         */
        runGET(params: {  "req"?: CustomerLead; "authToken"?: string; }, options?: any) {
            return LeadGenApiFp.runGET(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary Run
         * @param req
         * @param authToken
         */
        runPOST(params: {  "req"?: CustomerLead; "authToken"?: string; }, options?: any) {
            return LeadGenApiFp.runPOST(params, options)(fetch, basePath);
        },
    };
};


/**
 * PostQuestionApi - fetch parameter creator
 */
export const PostQuestionApiFetchParamCreator = {
    /**
     *
     * @summary GetQuestions
     * @param req
     */
    postQuestion(params: {  "req"?: SurveyQuestion; }, options?: any): FetchArgs {
        const baseUrl = `/api/question`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["req"]) {
            fetchOptions.body = JSON.stringify(params["req"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * PostQuestionApi - functional programming interface
 */
export const PostQuestionApiFp = {
    /**
     *
     * @summary GetQuestions
     * @param req
     */
    postQuestion(params: { "req"?: SurveyQuestion;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TableResult> {
        const fetchArgs = PostQuestionApiFetchParamCreator.postQuestion(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * PostQuestionApi - object-oriented interface
 */
export class PostQuestionApi extends BaseAPI {
    /**
     *
     * @summary GetQuestions
     * @param req
     */
    postQuestion(params: {  "req"?: SurveyQuestion; }, options?: any) {
        return PostQuestionApiFp.postQuestion(params, options)(this.fetch, this.basePath);
    }
};

/**
 * PostQuestionApi - factory interface
 */
export const PostQuestionApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary GetQuestions
         * @param req
         */
        postQuestion(params: {  "req"?: SurveyQuestion; }, options?: any) {
            return PostQuestionApiFp.postQuestion(params, options)(fetch, basePath);
        },
    };
};


/**
 * QuestionApi - fetch parameter creator
 */
export const QuestionApiFetchParamCreator = {
    /**
     *
     * @summary GetQuestions
     */
    getQuestions(options?: any): FetchArgs {
        const baseUrl = `/api/question`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * QuestionApi - functional programming interface
 */
export const QuestionApiFp = {
    /**
     *
     * @summary GetQuestions
     */
    getQuestions(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SurveyQuestion>> {
        const fetchArgs = QuestionApiFetchParamCreator.getQuestions(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * QuestionApi - object-oriented interface
 */
export class QuestionApi extends BaseAPI {
    /**
     *
     * @summary GetQuestions
     */
    getQuestions(options?: any) {
        return QuestionApiFp.getQuestions(options)(this.fetch, this.basePath);
    }
};

/**
 * QuestionApi - factory interface
 */
export const QuestionApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary GetQuestions
         */
        getQuestions(options?: any) {
            return QuestionApiFp.getQuestions(options)(fetch, basePath);
        },
    };
};


/**
 * ResourceApi - fetch parameter creator
 */
export const ResourceApiFetchParamCreator = {
    /**
     *
     * @summary AvailabilityRules
     * @param req
     * @param authToken
     */
    availabilityRulesDELETE(params: {  "req"?: Array<AvailabilityRule>; "authToken"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/timeslot/rules`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "authToken": params["authToken"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["req"]) {
            fetchOptions.body = JSON.stringify(params["req"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary AvailabilityRules
     * @param authToken
     */
    availabilityRulesGET(params: {  "authToken"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/timeslot/rules`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "authToken": params["authToken"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary AvailabilityRules
     * @param req
     * @param authToken
     */
    availabilityRulesPOST(params: {  "req"?: Array<AvailabilityRule>; "authToken"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/timeslot/rules`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "authToken": params["authToken"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["req"]) {
            fetchOptions.body = JSON.stringify(params["req"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary AvailabilityRules
     * @param req
     * @param authToken
     */
    availabilityRulesPUT(params: {  "req"?: Array<AvailabilityRule>; "authToken"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/timeslot/rules`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "authToken": params["authToken"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["req"]) {
            fetchOptions.body = JSON.stringify(params["req"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary Locations
     * @param locationId
     * @param authToken
     */
    locationsGET(params: {  "locationId"?: string; "authToken"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/locations`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "locationId": params["locationId"],
            "authToken": params["authToken"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary Locations
     * @param locationId
     * @param authToken
     */
    locationsPOST(params: {  "locationId"?: string; "authToken"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/locations`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "locationId": params["locationId"],
            "authToken": params["authToken"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ResourceApi - functional programming interface
 */
export const ResourceApiFp = {
    /**
     *
     * @summary AvailabilityRules
     * @param req
     * @param authToken
     */
    availabilityRulesDELETE(params: { "req"?: Array<AvailabilityRule>; "authToken"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultListAvailabilityRule> {
        const fetchArgs = ResourceApiFetchParamCreator.availabilityRulesDELETE(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary AvailabilityRules
     * @param authToken
     */
    availabilityRulesGET(params: { "authToken"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultListAvailabilityRule> {
        const fetchArgs = ResourceApiFetchParamCreator.availabilityRulesGET(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary AvailabilityRules
     * @param req
     * @param authToken
     */
    availabilityRulesPOST(params: { "req"?: Array<AvailabilityRule>; "authToken"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultListAvailabilityRule> {
        const fetchArgs = ResourceApiFetchParamCreator.availabilityRulesPOST(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary AvailabilityRules
     * @param req
     * @param authToken
     */
    availabilityRulesPUT(params: { "req"?: Array<AvailabilityRule>; "authToken"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultListAvailabilityRule> {
        const fetchArgs = ResourceApiFetchParamCreator.availabilityRulesPUT(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary Locations
     * @param locationId
     * @param authToken
     */
    locationsGET(params: { "locationId"?: string; "authToken"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Location>> {
        const fetchArgs = ResourceApiFetchParamCreator.locationsGET(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary Locations
     * @param locationId
     * @param authToken
     */
    locationsPOST(params: { "locationId"?: string; "authToken"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Location>> {
        const fetchArgs = ResourceApiFetchParamCreator.locationsPOST(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ResourceApi - object-oriented interface
 */
export class ResourceApi extends BaseAPI {
    /**
     *
     * @summary AvailabilityRules
     * @param req
     * @param authToken
     */
    availabilityRulesDELETE(params: {  "req"?: Array<AvailabilityRule>; "authToken"?: string; }, options?: any) {
        return ResourceApiFp.availabilityRulesDELETE(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary AvailabilityRules
     * @param authToken
     */
    availabilityRulesGET(params: {  "authToken"?: string; }, options?: any) {
        return ResourceApiFp.availabilityRulesGET(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary AvailabilityRules
     * @param req
     * @param authToken
     */
    availabilityRulesPOST(params: {  "req"?: Array<AvailabilityRule>; "authToken"?: string; }, options?: any) {
        return ResourceApiFp.availabilityRulesPOST(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary AvailabilityRules
     * @param req
     * @param authToken
     */
    availabilityRulesPUT(params: {  "req"?: Array<AvailabilityRule>; "authToken"?: string; }, options?: any) {
        return ResourceApiFp.availabilityRulesPUT(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Locations
     * @param locationId
     * @param authToken
     */
    locationsGET(params: {  "locationId"?: string; "authToken"?: string; }, options?: any) {
        return ResourceApiFp.locationsGET(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Locations
     * @param locationId
     * @param authToken
     */
    locationsPOST(params: {  "locationId"?: string; "authToken"?: string; }, options?: any) {
        return ResourceApiFp.locationsPOST(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ResourceApi - factory interface
 */
export const ResourceApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary AvailabilityRules
         * @param req
         * @param authToken
         */
        availabilityRulesDELETE(params: {  "req"?: Array<AvailabilityRule>; "authToken"?: string; }, options?: any) {
            return ResourceApiFp.availabilityRulesDELETE(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary AvailabilityRules
         * @param authToken
         */
        availabilityRulesGET(params: {  "authToken"?: string; }, options?: any) {
            return ResourceApiFp.availabilityRulesGET(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary AvailabilityRules
         * @param req
         * @param authToken
         */
        availabilityRulesPOST(params: {  "req"?: Array<AvailabilityRule>; "authToken"?: string; }, options?: any) {
            return ResourceApiFp.availabilityRulesPOST(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary AvailabilityRules
         * @param req
         * @param authToken
         */
        availabilityRulesPUT(params: {  "req"?: Array<AvailabilityRule>; "authToken"?: string; }, options?: any) {
            return ResourceApiFp.availabilityRulesPUT(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary Locations
         * @param locationId
         * @param authToken
         */
        locationsGET(params: {  "locationId"?: string; "authToken"?: string; }, options?: any) {
            return ResourceApiFp.locationsGET(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary Locations
         * @param locationId
         * @param authToken
         */
        locationsPOST(params: {  "locationId"?: string; "authToken"?: string; }, options?: any) {
            return ResourceApiFp.locationsPOST(params, options)(fetch, basePath);
        },
    };
};


/**
 * ScheduleApi - fetch parameter creator
 */
export const ScheduleApiFetchParamCreator = {
    /**
     *
     * @summary AppointmentHandler
     * @param req
     * @param authToken
     * @param withContrast
     * @param locationId
     * @param search
     */
    appointmentHandlerDELETE(params: {  "req"?: Appointment; "authToken"?: string; "withContrast"?: boolean; "locationId"?: string; "search"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/appointment`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "authToken": params["authToken"],
            "withContrast": params["withContrast"],
            "locationId": params["locationId"],
            "search": params["search"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["req"]) {
            fetchOptions.body = JSON.stringify(params["req"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary AppointmentHandler
     * @param authToken
     * @param withContrast
     * @param locationId
     * @param search
     */
    appointmentHandlerGET(params: {  "authToken"?: string; "withContrast"?: boolean; "locationId"?: string; "search"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/appointment`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "authToken": params["authToken"],
            "withContrast": params["withContrast"],
            "locationId": params["locationId"],
            "search": params["search"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary AppointmentHandler
     * @param req
     * @param authToken
     * @param withContrast
     * @param locationId
     * @param search
     */
    appointmentHandlerPOST(params: {  "req"?: Appointment; "authToken"?: string; "withContrast"?: boolean; "locationId"?: string; "search"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/appointment`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "authToken": params["authToken"],
            "withContrast": params["withContrast"],
            "locationId": params["locationId"],
            "search": params["search"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["req"]) {
            fetchOptions.body = JSON.stringify(params["req"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary AppointmentHandler
     * @param req
     * @param authToken
     * @param withContrast
     * @param locationId
     * @param search
     */
    appointmentHandlerPUT(params: {  "req"?: Appointment; "authToken"?: string; "withContrast"?: boolean; "locationId"?: string; "search"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/appointment`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "authToken": params["authToken"],
            "withContrast": params["withContrast"],
            "locationId": params["locationId"],
            "search": params["search"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["req"]) {
            fetchOptions.body = JSON.stringify(params["req"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary AppointmentRef
     * @param authToken
     * @param search
     */
    appointmentRefDELETE(params: {  "authToken"?: string; "search"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/appointmentByRef`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "authToken": params["authToken"],
            "search": params["search"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary AppointmentRef
     * @param authToken
     * @param search
     */
    appointmentRefGET(params: {  "authToken"?: string; "search"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/appointmentByRef`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "authToken": params["authToken"],
            "search": params["search"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary SendConfirmationEmail
     * @param req
     * @param authToken
     * @param notifyAdmin
     */
    sendConfirmationEmailPOST(params: {  "req"?: Appointment; "authToken"?: string; "notifyAdmin"?: boolean; }, options?: any): FetchArgs {
        const baseUrl = `/api/sendemail`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "authToken": params["authToken"],
            "notifyAdmin": params["notifyAdmin"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["req"]) {
            fetchOptions.body = JSON.stringify(params["req"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary ServiceType
     * @param locationId
     */
    serviceTypesGET(params: {  "locationId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/serviceType`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "locationId": params["locationId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary TimeSlots
     * @param authToken
     * @param withContrast
     * @param length
     * @param locationId
     * @param skip
     */
    timeSlotsGET(params: {  "authToken"?: string; "withContrast"?: boolean; "length"?: number; "locationId"?: string; "skip"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/timeslots`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "authToken": params["authToken"],
            "withContrast": params["withContrast"],
            "length": params["length"],
            "locationId": params["locationId"],
            "skip": params["skip"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ScheduleApi - functional programming interface
 */
export const ScheduleApiFp = {
    /**
     *
     * @summary AppointmentHandler
     * @param req
     * @param authToken
     * @param withContrast
     * @param locationId
     * @param search
     */
    appointmentHandlerDELETE(params: { "req"?: Appointment; "authToken"?: string; "withContrast"?: boolean; "locationId"?: string; "search"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultListAppointment> {
        const fetchArgs = ScheduleApiFetchParamCreator.appointmentHandlerDELETE(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary AppointmentHandler
     * @param authToken
     * @param withContrast
     * @param locationId
     * @param search
     */
    appointmentHandlerGET(params: { "authToken"?: string; "withContrast"?: boolean; "locationId"?: string; "search"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultListAppointment> {
        const fetchArgs = ScheduleApiFetchParamCreator.appointmentHandlerGET(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary AppointmentHandler
     * @param req
     * @param authToken
     * @param withContrast
     * @param locationId
     * @param search
     */
    appointmentHandlerPOST(params: { "req"?: Appointment; "authToken"?: string; "withContrast"?: boolean; "locationId"?: string; "search"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultListAppointment> {
        const fetchArgs = ScheduleApiFetchParamCreator.appointmentHandlerPOST(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary AppointmentHandler
     * @param req
     * @param authToken
     * @param withContrast
     * @param locationId
     * @param search
     */
    appointmentHandlerPUT(params: { "req"?: Appointment; "authToken"?: string; "withContrast"?: boolean; "locationId"?: string; "search"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultListAppointment> {
        const fetchArgs = ScheduleApiFetchParamCreator.appointmentHandlerPUT(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary AppointmentRef
     * @param authToken
     * @param search
     */
    appointmentRefDELETE(params: { "authToken"?: string; "search"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultAppointment> {
        const fetchArgs = ScheduleApiFetchParamCreator.appointmentRefDELETE(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary AppointmentRef
     * @param authToken
     * @param search
     */
    appointmentRefGET(params: { "authToken"?: string; "search"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultAppointment> {
        const fetchArgs = ScheduleApiFetchParamCreator.appointmentRefGET(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary SendConfirmationEmail
     * @param req
     * @param authToken
     * @param notifyAdmin
     */
    sendConfirmationEmailPOST(params: { "req"?: Appointment; "authToken"?: string; "notifyAdmin"?: boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultListAppointment> {
        const fetchArgs = ScheduleApiFetchParamCreator.sendConfirmationEmailPOST(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary ServiceType
     * @param locationId
     */
    serviceTypesGET(params: { "locationId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultListServiceType> {
        const fetchArgs = ScheduleApiFetchParamCreator.serviceTypesGET(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary TimeSlots
     * @param authToken
     * @param withContrast
     * @param length
     * @param locationId
     * @param skip
     */
    timeSlotsGET(params: { "authToken"?: string; "withContrast"?: boolean; "length"?: number; "locationId"?: string; "skip"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultListSlotAvailabilityDate> {
        const fetchArgs = ScheduleApiFetchParamCreator.timeSlotsGET(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ScheduleApi - object-oriented interface
 */
export class ScheduleApi extends BaseAPI {
    /**
     *
     * @summary AppointmentHandler
     * @param req
     * @param authToken
     * @param withContrast
     * @param locationId
     * @param search
     */
    appointmentHandlerDELETE(params: {  "req"?: Appointment; "authToken"?: string; "withContrast"?: boolean; "locationId"?: string; "search"?: string; }, options?: any) {
        return ScheduleApiFp.appointmentHandlerDELETE(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary AppointmentHandler
     * @param authToken
     * @param withContrast
     * @param locationId
     * @param search
     */
    appointmentHandlerGET(params: {  "authToken"?: string; "withContrast"?: boolean; "locationId"?: string; "search"?: string; }, options?: any) {
        return ScheduleApiFp.appointmentHandlerGET(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary AppointmentHandler
     * @param req
     * @param authToken
     * @param withContrast
     * @param locationId
     * @param search
     */
    appointmentHandlerPOST(params: {  "req"?: Appointment; "authToken"?: string; "withContrast"?: boolean; "locationId"?: string; "search"?: string; }, options?: any) {
        return ScheduleApiFp.appointmentHandlerPOST(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary AppointmentHandler
     * @param req
     * @param authToken
     * @param withContrast
     * @param locationId
     * @param search
     */
    appointmentHandlerPUT(params: {  "req"?: Appointment; "authToken"?: string; "withContrast"?: boolean; "locationId"?: string; "search"?: string; }, options?: any) {
        return ScheduleApiFp.appointmentHandlerPUT(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary AppointmentRef
     * @param authToken
     * @param search
     */
    appointmentRefDELETE(params: {  "authToken"?: string; "search"?: string; }, options?: any) {
        return ScheduleApiFp.appointmentRefDELETE(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary AppointmentRef
     * @param authToken
     * @param search
     */
    appointmentRefGET(params: {  "authToken"?: string; "search"?: string; }, options?: any) {
        return ScheduleApiFp.appointmentRefGET(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary SendConfirmationEmail
     * @param req
     * @param authToken
     * @param notifyAdmin
     */
    sendConfirmationEmailPOST(params: {  "req"?: Appointment; "authToken"?: string; "notifyAdmin"?: boolean; }, options?: any) {
        return ScheduleApiFp.sendConfirmationEmailPOST(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary ServiceType
     * @param locationId
     */
    serviceTypesGET(params: {  "locationId"?: string; }, options?: any) {
        return ScheduleApiFp.serviceTypesGET(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary TimeSlots
     * @param authToken
     * @param withContrast
     * @param length
     * @param locationId
     * @param skip
     */
    timeSlotsGET(params: {  "authToken"?: string; "withContrast"?: boolean; "length"?: number; "locationId"?: string; "skip"?: number; }, options?: any) {
        return ScheduleApiFp.timeSlotsGET(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ScheduleApi - factory interface
 */
export const ScheduleApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary AppointmentHandler
         * @param req
         * @param authToken
         * @param withContrast
         * @param locationId
         * @param search
         */
        appointmentHandlerDELETE(params: {  "req"?: Appointment; "authToken"?: string; "withContrast"?: boolean; "locationId"?: string; "search"?: string; }, options?: any) {
            return ScheduleApiFp.appointmentHandlerDELETE(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary AppointmentHandler
         * @param authToken
         * @param withContrast
         * @param locationId
         * @param search
         */
        appointmentHandlerGET(params: {  "authToken"?: string; "withContrast"?: boolean; "locationId"?: string; "search"?: string; }, options?: any) {
            return ScheduleApiFp.appointmentHandlerGET(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary AppointmentHandler
         * @param req
         * @param authToken
         * @param withContrast
         * @param locationId
         * @param search
         */
        appointmentHandlerPOST(params: {  "req"?: Appointment; "authToken"?: string; "withContrast"?: boolean; "locationId"?: string; "search"?: string; }, options?: any) {
            return ScheduleApiFp.appointmentHandlerPOST(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary AppointmentHandler
         * @param req
         * @param authToken
         * @param withContrast
         * @param locationId
         * @param search
         */
        appointmentHandlerPUT(params: {  "req"?: Appointment; "authToken"?: string; "withContrast"?: boolean; "locationId"?: string; "search"?: string; }, options?: any) {
            return ScheduleApiFp.appointmentHandlerPUT(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary AppointmentRef
         * @param authToken
         * @param search
         */
        appointmentRefDELETE(params: {  "authToken"?: string; "search"?: string; }, options?: any) {
            return ScheduleApiFp.appointmentRefDELETE(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary AppointmentRef
         * @param authToken
         * @param search
         */
        appointmentRefGET(params: {  "authToken"?: string; "search"?: string; }, options?: any) {
            return ScheduleApiFp.appointmentRefGET(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary SendConfirmationEmail
         * @param req
         * @param authToken
         * @param notifyAdmin
         */
        sendConfirmationEmailPOST(params: {  "req"?: Appointment; "authToken"?: string; "notifyAdmin"?: boolean; }, options?: any) {
            return ScheduleApiFp.sendConfirmationEmailPOST(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary ServiceType
         * @param locationId
         */
        serviceTypesGET(params: {  "locationId"?: string; }, options?: any) {
            return ScheduleApiFp.serviceTypesGET(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary TimeSlots
         * @param authToken
         * @param withContrast
         * @param length
         * @param locationId
         * @param skip
         */
        timeSlotsGET(params: {  "authToken"?: string; "withContrast"?: boolean; "length"?: number; "locationId"?: string; "skip"?: number; }, options?: any) {
            return ScheduleApiFp.timeSlotsGET(params, options)(fetch, basePath);
        },
    };
};

